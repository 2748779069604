import React from "react"
import {Throttles} from "../../components";

export const ThrottlesPage = () => {
    return (
        <div>
            <h2>Throttles</h2>

            <div className="container">
                <Throttles/>
            </div>
        </div>
    )
}
